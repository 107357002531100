import ItemService from "../services/item.service";

const initialState = {
  items: {
    id: "", //required
    item_no: "", //required
    name: "", //required
    description: "", //required
    unit_price: "", //optional
    quantity: "", //required
    type: "", //required
    created_at: "", //required
    created_by: "", //optional
    active: "", //required
    category_id: "", //required
  },
  item_number_generated: "",
  item_list: [],
  item_category: [],
  item_added_recently: [],
  selectedItemsList: [],
};

export const item = {
  namespaced: true,
  state: initialState,
  getters: {
    get_item_added_recently(state) {
      return state.item_added_recently;
    },
    getSelectedItemsList(state) {
      return state.selectedItemsList;
    },
  },
  actions: {
    getList({ commit }, item) {
      return ItemService.getList(item)
        .then((response) => {
          commit("save", response.data);
          return response.data;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    generateItemNumber({ commit }, item) {
      return ItemService.generateItemNumber(item).then(
        (response) => {
          commit("saveItemNumber", response.data.response);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createItem({ commit }, item) {
      return ItemService.createItem(item).then(
        (response) => {
          commit("addRecentItemsAddPage", item);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateAddedItem({ commit }, item) {
      return ItemService.updateAddedItem(item).then(
        (response) => {
          commit("removeItemFromRecentList", item.item_no);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateAddedItemQuotation({ commit }, item) {
      return ItemService.updateAddedItemQuotation(item).then(
        (response) => {
          commit("removeItemFromRecentList", item.item_no);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getAddedItem({ commit }, item) {
      return ItemService.getAddedItem(item).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getAddedItemQuotation({ commit }, item) {
      return ItemService.getAddedItemQuotation(item).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateItem({ commit }, item) {
      return ItemService.updateItem(item).then(
        (response) => {
          // commit('addRecentItemsAddPage', item);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    fetchCategory({ commit }, item) {
      return ItemService.fetchCategory(item).then(
        (response) => {
          commit("itemCategory", response.data.response);
          return Promise.resolve(response.data.response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createCategory({ commit }, item) {
      return ItemService.createCategory(item).then(
        (response) => {
          console.log(commit);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteCategory({ commit }, item) {
      return ItemService.deleteCategory(item).then(
        (response) => {
          console.log(commit);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteItem({ commit }, item) {
      return ItemService.deleteItem(item).then(
        (response) => {
          commit("removeItemFromRecentList", item.item_no);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    save(state, item) {
      state.item_list = item.response;
    },
    saveItemNumber(state, itemNumber) {
      state.item_number_generated = itemNumber;
      localStorage.setItem("item_number_generated", itemNumber);
    },
    itemListByNumber(state, single_item) {
      let items =
        JSON.parse(localStorage.getItem("item_recently_added_items")) || [];
      items.push(single_item);
      localStorage.setItem("item_recently_added_items", JSON.stringify(items));
    },
    itemCategory(state, category) {
      state.item_category = category;
      localStorage.setItem("item_category", category);
    },
    removeItemFromRecentList(state, itemNoToRemove) {
      const index = state.item_added_recently.find(
        (item) => item.item_no === itemNoToRemove
      );
      if (index !== -1) {
        state.item_added_recently.splice(index, 1);
      }
    },
    addToRecentItems(state, itemNoToAdd) {
      // Retrieve or initialize the recent items list from localStorage
      const items =
        JSON.parse(localStorage.getItem("item_recently_added_items")) || [];

      let itemToAdd;

      // Check if itemNoToAdd is an object or an identifier
      if (typeof itemNoToAdd === "object" && itemNoToAdd !== null) {
        itemToAdd = itemNoToAdd;
      } else {
        // Search for the item in item_list if itemNoToAdd is an identifier
        itemToAdd = state.item_list.find(
          (item) => item.item_no === itemNoToAdd
        );
      }

      // Add the item to the list if it exists
      if (itemToAdd) {
        // Optionally check if the item is already in the list to avoid duplicates
        items.push(itemToAdd);
        localStorage.setItem(
          "item_recently_added_items",
          JSON.stringify(items)
        );
        state.selectedItemsList = items;
      }
    },
    addToRecentItemsInvoice(state, selectedItems) {
      // Filter the item_list to get selected items based on item numbers
      const selectedItemsList = selectedItems;
      localStorage.setItem(
        "item_recently_added_items",
        JSON.stringify(selectedItemsList)
      );
      // Save the filtered selected items into selectedItemsList state
      state.selectedItemsList = selectedItemsList;
    },

    addToRecentItemsQuotation(state, selectedItems) {
      // Filter the item_list to get selected items based on item numbers
      const selectedItemsList = selectedItems;
      localStorage.setItem(
        "item_recently_added_items",
        JSON.stringify(selectedItemsList)
      );
      // Save the filtered selected items into selectedItemsList state
      state.selectedItemsList = selectedItemsList;
    },
    addRecentItemsAddPage(state, item_added) {
      state.item_added_recently.push(item_added);
    },
    updateRecentItemsAddPage(state, item_added) {},
    clearRecentItemsAddPage(state) {
      state.item_added_recently = [];
    },
    removeFromRecentItemsInvoice(state, itemNoToRemove) {
      // Find the index of the item with the provided item_no
      const indexToRemove = state.selectedItemsList.findIndex(
        (item) => item.item_no == itemNoToRemove
      );

      if (indexToRemove !== -1) {
        // Remove the item from selectedItemsList if found
        state.selectedItemsList.splice(indexToRemove, 1);
      } else {
        console.error(
          `Item with item_no ${itemNoToRemove} not found in the selected items.`
        );
      }

    },
    clearToRecentItems(state) {
      state.selectedItemsList = [];
      localStorage.setItem("item_recently_added_items", JSON.stringify([]));
    },
  },
};
