<template>
  <div class="card card-form _control_panel">
    <div class="card-header">
      <div class="card-title">Control Panel</div>
    </div>
    <div class="card-body">
      <div class="base-form h-100 justify-content-between flex-column">
        <div class="input-group _group-xl mb-3">
          <span class="input-group-text prepend"> Invoice Terms (days) </span>
          <select v-model="invoice_term" class="form-select" required>
            <option value="" selected disabled>Select</option>
            <option v-for="day in 31" :value="day" :key="day">
              {{ day }}
            </option>
          </select>
        </div>
        <div class="input-group _group-xl mb-3">
          <span class="input-group-text prepend">Quotation Terms (days) </span>
          <select v-model="quotation_term" class="form-select" required>
            <option value="" selected disabled>Select</option>
            <option v-for="day in 31" :value="day" :key="day">
              {{ day }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-footer d-flex align-items-end">
        <button
          type="submit"
          role="button"
          class="btn btn-success float-end mb-4"
          @click="saveData"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ControlPanel",
  data() {
    return {
      loadingInstance: null,
      invoice_term: "",
      quotation_term: "",
    };
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    saveData() {},
  },
};
</script>

<style scoped></style>
