<template>
  <form @submit.prevent="handleSubmit" class="base-form">
    <h5 class="form-title">Limited Company contact details</h5>
    <div class="mb-3">
      <input type="text" name="comp_name" v-model="form.companyname" required class="form-control"
        placeholder="Name/Company Name*" />
    </div>

    <div class="mb-3">
      <select v-model="form.business_category_id" required class="form-select">
        <option disabled selected value="">Business Category*</option>
        <option v-for="option in business_category_options" :value="option.id" :key="option.id">
          {{ option.name }}
        </option>
      </select>
    </div>
    <div class="row flex-row flex-nowrap">
      <div class="d-flex flex-fill flex-column">
        <div class="mb-3">
          <input type="text" v-model="form.mobile" required class="form-control" placeholder="Mobile no*" />
        </div>
        <div class="mb-3">
          <input type="text" v-model="form.phone1" class="form-control" placeholder="Phone no" />
        </div>
      </div>
      <div class="btn-file-wrapper">
        <label for="logo-file" class="btn btn-file">
          <span class="file-icon">
            <i class="fa fa-plus"></i>
          </span>
        </label>

        <input type="file" id="logo-file" ref="uploads" style="display: none" @change="handleFileSelect(event)" />
        <span class="form-text">Upload Logo</span>
      </div>
    </div>

    <!-- <div class="row d-flex align-items-center justify-content-center m-0 mb-2">
      <button type="button" name="toggleDrop" class="btn btn-toggleDrop" data-bs-toggle="collapse"
        data-bs-target="#formMore" aria-expanded="true" aria-controls="formMore">
        <span><i class="fas fa-chevron-down"></i> </span>
        <span><i class="fas fa-chevron-down"></i> </span>
      </button>
    </div> -->

    <div class="collapse show" id="formMore">
      <div class="mb-3">
        <input type="text" v-model="form.postal_address" class="form-control" placeholder="Postal address / Street 1" />
      </div>

      <div class="mb-3">
        <input type="text" v-model="form.street2" class="form-control" placeholder="Street 2" />
      </div>
      <!-- <div class="mb-3">
        <input type="text" v-model="form.state" class="form-control" placeholder="State" />
      </div> -->

      <div class="row g-3">
        <div class="col-sm-12 col-md-12 col-lg-7 mb-3">
          <select v-model="form.state" class="form-select" placeholder="Country">
            <option selected disabled>State</option>
            <option v-for="option in states" :value="option.name" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <!--<input type="text" name="county" class="form-control" placeholder="County/State"/>-->
        </div>
        <div class="col-sm-12 col-md-12 col-lg-5 mb-3">
          <input type="text" v-model="form.zip" class="form-control" placeholder="Zip/Postal Code" />
        </div>
      </div>
      <div class="mb-3">
        <input type="text" v-model="form.website" class="form-control" placeholder="Website" />
      </div>
    </div>

    <div class="form-footer d-flex align-items-end mt-4">
      <button type="submit" role="button" :disabled="loading" class="btn btn-indigo">
        Next
      </button>
    </div>
  </form>
</template>
<script>
import { BUSINESS_CATEGORIES, COUNTRIES_LIST } from "../../config/url";


export default {
  name: "SoleTraderFormCompany",
  data() {
    return {
      loadingInstance: null,
      loading: false,
      countries: null,
      business_categories: null,
      states: "",
      form: {
        fullname: "",
        business_category_id: "",
        postal_address: "",
        street2: "",
        state: "",
        zip: "",
        phone1: "",
        mobile: "",
        website: "",
        companyname: "",
        companytype: "Limited Company",
        image: "",
        is_logo: "N",
      },
    };
  },
  mounted() {
    let user_details_local = this.$store.getters["auth/getUserData"];
    this.country_id = user_details_local.country_id;
    this.form.fullname = user_details_local.welcomename;
    this.$nextTick(() => {
      this.fetchBusinessCategories();
      this.fetchStateByCountry();
    })
  },
  computed: {

    country_options() {
      if (this.countries) {
        return this.countries.map((v) => {
          return {
            id: v.id,
            name: v.name,
          };
        });
      }
      return [];
    },
    business_category_options() {
      if (this.business_categories) {
        return this.business_categories.map((v) => {
          return {
            id: v.id,
            name: v.name,
          };
        });
      }
      return [];
    },
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    async fetchBusinessCategories() {
      this.business_categories = await this.axios
        .get(BUSINESS_CATEGORIES)
        .then(function (res) {
          return res.data.message;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchCountries() {
      this.countries = await this.axios
        .get(COUNTRIES_LIST)
        .then(function (res) {
          return res.data.response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchStateByCountry() {
      this.showLoading();

      this.$store
        .dispatch("client/fetchStateByCountry", { country_id: this.country_id })
        .then((res) => {
          if (res.status == 1) {
            this.states = res.response;
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    handleSubmit() {
      this.showLoading();
      this.$store
        .dispatch("company/save", this.form)
        .then((res) => {
          if (res.status == 1) {
            this.$toast.success("Registraion completed.");
            this.$router.push({ name: "user-dashboard" });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    handleFileSelect() {
      const file = event.target.files[0];
      // console.log(file);
      
      if (file) {
        this.form.image = [file];
        this.form.is_logo = "Y";

        // Create a preview URL for the selected image
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>